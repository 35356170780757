import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' }
    ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: 'Total Sales Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: 'Response Rate',
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate60.metricId,
                displayName: 'Response Rate < 1 Hr',
                propertyName: Enums.leadMetrics.responseRate60.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
                displayName: 'Avg. Response Time (48 Hrs)',
                propertyName: Enums.leadMetrics.averageResponseTime48Hour.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.averageResponseTime.metricId,
                displayName: 'Overall Average Response Time',
                propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.appointmentRate.metricId,
                displayName: Enums.leadMetrics.appointmentRate.name,
                propertyName: Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            }
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.appointmentRate.metricId,
        metricOneColorHexCode: '#4c4c4c',
        metricTwoColorHexCode: '#C3002F'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
            // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // can use row to determine entity type if needed

        {
            show: true,
            header: 'Total Sales Leads',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Total Sales Leads',
            columnDef: 'leadVolumePreviousMTD',
            isMom: true,
            isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Sales Leads MOM',
            columnDef: 'leadVolumeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Total Sales Leads',
            columnDef: 'leadVolumePreviousYear',
            isMom: false,
            isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Sales Leads YOY',
            columnDef: 'leadVolumeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate',
            columnDef: 'responseRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate MOM',
            columnDef: 'responseRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate',
            columnDef: 'responseRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate YOY',
            columnDef: 'responseRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Response Rate < 1 Hr',
            columnDef: 'responseRate60',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate < 1 Hr',
            columnDef: 'responseRate60PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 1 Hr MOM',
            columnDef: 'responseRate60MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate < 1 Hr',
            columnDef: 'responseRate60PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 1 Hr YOY',
            columnDef: 'responseRate60YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48Hour',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48HourPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Avg. Response Time (48 Hrs) MOM',
            columnDef: 'averageResponseTime48HourMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Avg. Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48HourPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Avg. Response Time (48 Hrs) YOY',
            columnDef: 'averageResponseTime48HourYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Overall Average Response Time',
            columnDef: 'averageResponseTime',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time MOM',
            columnDef: 'averageResponseTimeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time YOY',
            columnDef: 'averageResponseTimeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
        },

        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Appointment Rate',
            columnDef: 'appointmentRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate MOM',
            columnDef: 'appointmentRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Appointment Rate',
            columnDef: 'appointmentRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate YOY',
            columnDef: 'appointmentRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];
    public trendTableMetrics: INameValuePair[] = [
        {name: 'Lead Volume', value: 'leadVolume'},
        {name: 'Third Party Leads', value: 'thirdPartyLeads'},
        {name: 'Unique Lead Volume', value: 'brandNabrLeads'},
        {name: 'Brand Leads', value: 'otherBrandSiteLeads'},
        {name: 'Dealer Generated Leads', value: 'dealerGeneratedLeads'},
        {name: 'Dealer Website Leads', value: 'dealerWebsiteLeads'},
        {name: 'Digital Retail Leads', value: 'digitalRetailLeads'},
        {name: 'Event Leads', value: 'eventLeads'},
        {name: 'Response Rate', value: 'responseRate'},
        {name: 'Response Rate < 1h', value: 'responseRate60'},
        {name: 'Avg. Response Time (48 Hr)', value: 'averageResponseTime48Hour'},
        {name: 'Appointment Rate', value: 'appointmentRate'},
    ];
}
