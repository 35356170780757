import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from 'highcharts';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { ReportsService } from '../../../services/api/reports.service';
import { PanelElementFormattingService } from '../panel-element-formatting.service';
import { PanelElementResolverService } from '../panel-element-resolver.service';
import * as SharedServices from '../../../services/services-index';
import { ChartService } from '../../chart/chart.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../_store/app-state.model';
import { AppSelectors } from '../../../../_store/selector-types';

const clone = require('rfdc/default');

type PanelMetric = { name: string, displayName: string };
type SelectedConfiguration = { config: Models.PanelConfiguration, selectedMetrics: string[] };

@Component({
  selector: 'indexed-visualization-panel',
  templateUrl: './indexed-visualization-panel.component.html',
  styleUrls: ['./indexed-visualization-panel.component.scss']
})
export class IndexedVisualizationPanelComponent implements OnInit, OnDestroy, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  @Input() dataSets: Models.DataSet[];
  @Input() rowPanelId: string;

  @Output() expandClicked = new EventEmitter<boolean>();

  panelTitle;
  headerConfig;
  subscriptions: Subscription[] = [];
  get panelConfigs() { return this.panelConfig.configurations }
  selectedConfiguration$ = new BehaviorSubject<SelectedConfiguration>(null);
  get selectedConfiguration() { return this.selectedConfiguration$.value; }

  panelMetrics$ = new BehaviorSubject<PanelMetric[]>([]);
  get panelMetrics() { return this.panelMetrics$.value; }
  selectedMetrics: string[] = [];
  translationService: Models.ITranslationService;
  locale: string;
  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();


  requestModel: Models.ReportRequestModel = {
    orderBy: '',
    orderAscending: false,
    reportType: 'data-panel-load',
    filters: {
      startDate: new Date(2018, 7, 1),
      endDate: new Date(2018, 8, 1),
      previousStartDate: new Date(),
      previousEndDate: new Date(),
      orgCode5: null,
      orgCode4: null,
      orgCode3: null,
      orgCode2: null,
      orgCode1: null,
      dealerCode: null,
      deviceTypeId: null,
      orgLookupTypeId: 1,
    }
  };

  constructor(
    private store$: Store<AppState>,
    private resolverService: PanelElementResolverService,
    private chartService: ChartService,
    private renderer2: Renderer2,
    private reportService: ReportsService,
    private filterService: SharedServices.FilterService,
    private spinnerService: SharedServices.SpinnerService,
    private helpTextService: SharedServices.HelpTextService,
    private localeService: SharedServices.LocaleService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(this.generateElements().subscribe());
    this.selectedConfiguration$.next({ config: this.panelConfigs[0], selectedMetrics: this.panelConfigs[0].elements[0].settings.defaultMetrics });
    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.generateElements().subscribe()
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  generateElements() {
    return this.selectedConfiguration$
      .pipe(
        filter(selectedConfiguration => !!selectedConfiguration?.config),
        switchMap(selectedConfiguration =>
          this.loadDataSet(selectedConfiguration.config.elements[0].settings.dataSet).pipe(
            map(dataSet => ({ selectedConfiguration, dataSet }))
          )
        ),
        map(result => {
          const { selectedConfiguration, dataSet } = result;
          const { config, selectedMetrics } = selectedConfiguration;
          const element = config.elements[0];
          this.panelTitle = config.label ?? config.name;

          // const newSelectedMetrics = selectedMetrics.length > 0 ? selectedMetrics :
          //   element.settings.defaultMetrics ?? [dataSet.columns.find(c => c.type === 'metric')?.name];

          // if (selectedMetrics !== newSelectedMetrics) {
          //   this.selectedMetrics$.next(newSelectedMetrics);
          // }

          this.selectedMetrics = selectedMetrics ?? [];

          if (!config.disableMetricSelection && this.selectedMetrics.length === 0) {
            this.selectedMetrics.push(dataSet.columns.find(c => c.type === 'metric')?.name);
          }

          this.configureMetrics(dataSet);

          const viewContainerRef = this.dynamicComponent.viewContainerRef;
          viewContainerRef.clear();

          const componentFactory = this.resolverService.resolveElementComponent(element.type)
          const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
          componentRef.instance.dataSet = dataSet;
          componentRef.instance.settings = element.settings;
          componentRef.instance.selectedMetric = this.selectedMetrics[0];
          componentRef.instance.selectedMetrics = this.selectedMetrics;

          componentRef.instance.panelConfiguration = config;
          this.renderer2.addClass(componentRef.location.nativeElement, 'indexed-visualization-panel-element');

          this.changeDetector.detectChanges();
        })
      )
  }

  configureMetrics(dataSet: Models.DataSet) {
    const columns = dataSet.columnSets.length > 0
      ? dataSet.columnSets[0].columnNames.map(columnName => dataSet.columns.find(c => c.name === columnName))
      : dataSet.columns;
    const metrics = columns.map(c => ({ name: c.name, displayName: c.displayName }));
    this.panelMetrics$.next(metrics);
  }

  loadDataSet(dataSetName: string): Observable<Models.DataSet> {
    // Check if the dataset with the given name exists in this.dataSets
    const existingDataSet = this.dataSets.find(
      (dataSet) => dataSet.name === dataSetName
    );

    // If the dataset exists, return it as an Observable
    if (existingDataSet) {
      return of(existingDataSet);
    }

    // this.showReportFilterBar$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
    //   map(routeData => {
    //     return routeData.useV5Filters;
    //   })
    // );

    return this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      switchMap(routeData =>
        iif(
          () => !!routeData.useV5Filters,
          this.getReportViewDataSet(routeData.reportName, dataSetName),
          this.getLegacyDataSet(dataSetName)
        )
      )
    );
  }

  getReportViewDataSet(reportName: string, dataSetName: string): Observable<Models.DataSet> {
    this.spinnerService.show();
    return this.filterService.getReportViewFilterRequestModel(reportName).pipe(
      takeUntil(this.destroyed$),
      map(requestModel => ({ ...requestModel, dataSets: [dataSetName] })),
      switchMap(requestModel => this.reportService.getDataSet(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        this.dataSets.push(dataSets[0]);

        return dataSets[0];
      }),
      tap({
        next: _ => this.spinnerService.hide(),
        error: err => {
          this.spinnerService.hide();
          appInsights.trackException(err);
          // Rethrow the error to ensure it's propagated up the stream
          throw err;
        }
      }),
      catchError(err => {
        // Handle the error and return a safe value or an empty Observable
        // Example: return of({} as Models.DataSet);
        // Or rethrow the error if you want to handle it further up the chain
        return throwError(() => err);
      })
    );
  }

  getLegacyDataSet(dataSetName: string): Observable<Models.DataSet> {
    return this.filterService.filter$.pipe(
      tap(_ => this.spinnerService.show()),
      map(updatedFilter => {
        const requestModel = { ...this.requestModel, filters: { ...updatedFilter }, dataSets: [dataSetName] };
        return requestModel;
      }),
      switchMap(requestModel => this.reportService.getDataSet(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        this.dataSets.push(dataSets[0]);

        return dataSets[0];
      }),
      tap(_ => this.spinnerService.hide())
    );
  }

  // translateDataSet(ds: Models.DataSet) {
  //   ds.columns.forEach(c => {
  //     const displayName = c.displayName ?? c.name
  //     c.displayName = c.type === 'metric'
  //       ? this.translationService?.getMetricNameTranslation(c.name, displayName, this.locale) ?? displayName
  //       : this.translationService?.getLabelTranslation(displayName, this.locale) ?? displayName
  //   });
  //   ds.rows.forEach(r => {
  //     r.forEach(m => {
  //       if (m.label == undefined && !!m.value) {
  //         const displayName = <string>m.value;
  //         m.value = this.translationService?.getChartDimensions(displayName, this.locale) ?? displayName
  //       }
  //     })
  //   })
  //   return ds;
  // }

  indexChanged($event) {
    const changedPanelConfig = this.panelConfigs.find(c => c.name === $event.value.name);
    this.selectedConfiguration$.next({ config: changedPanelConfig, selectedMetrics: changedPanelConfig.elements[0].settings.defaultMetrics });
  }

  selectedMetricChanged($event, index: number) {
    const updatedMetrics = [...this.selectedMetrics];
    updatedMetrics[index] = $event.value;

    this.selectedConfiguration$.next({ config: this.selectedConfiguration.config, selectedMetrics: updatedMetrics });
  }

  expandButtonClicked(evt) {
    this.expandClicked.emit(evt)
  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.selectedConfiguration.config.helpTextKey, this.selectedConfiguration.config.helpTextTitle);
  }

  toggleExpanded(evt) {
    const element = document.getElementById(this.rowPanelId);
    element.classList.toggle('wrap-row-panel');
    this.chartService.reflowCharts();
    // window.setTimeout(() => {
    //   element.scrollIntoView();
    // }, 300)
  }
}
