import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {

    public reportTitle = 'Lead Source Performance';

    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];

    useDefaultComparisonColumns = true;

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: 'Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.uniqueLeads.metricId,
                displayName: 'Unique Leads',
                propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: 'Lead Sales',
                propertyName: Enums.leadMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: 'Close Rate',
                propertyName: Enums.leadMetrics.closeRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },

            {
                id: Enums.leadMetrics.leadVolumePma.metricId,
                displayName: 'In PMA Leads',
                propertyName: Enums.leadMetrics.leadVolumePma.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: 71,
                displayName: 'In PMA Unique Leads',
                propertyName: 'uniqueLeadsPma',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSalesPma.metricId,
                displayName: 'In PMA Lead Sales',
                propertyName: Enums.leadMetrics.leadSalesPma.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.closeRatePma.metricId,
                displayName: 'In PMA Close Rate',
                propertyName: Enums.leadMetrics.closeRatePma.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },

            {
                id: Enums.leadMetrics.leadVolumeNonPma.metricId,
                displayName: 'Outside PMA Leads',
                propertyName: Enums.leadMetrics.leadVolumeNonPma.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: 72,
                displayName: 'Outside PMA Unique Leads',
                propertyName: 'uniqueLeadsNonPma',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSalesNonPma.metricId,
                displayName: 'Outside PMA Lead Sales',
                propertyName: Enums.leadMetrics.leadSalesNonPma.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.closeRateNonPma.metricId,
                displayName: 'Outside PMA Close Rate',
                propertyName: Enums.leadMetrics.closeRateNonPma.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },

            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: 'Response Rate',
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate60.metricId,
                displayName: 'Response Rate < 1hr',
                propertyName: Enums.leadMetrics.responseRate60.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.appointmentRate.metricId,
                displayName: 'Appointment Rate',
                propertyName: Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.leadSales.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    }

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Leads',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Unique Leads',
            columnDef: 'uniqueLeads',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'leadSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'closeRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'In PMA Leads',
            columnDef: 'leadVolumePma',
            columnClasses: ['left-border'],
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'In PMA Unique Leads',
            columnDef: 'uniqueLeadsPma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'In PMA Lead Sales',
            columnDef: 'leadSalesPma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'In PMA Close Rate',
            columnDef: 'closeRatePma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Outside PMA Leads',
            columnDef: 'leadVolumeNonPma',
            columnClasses: ['left-border'],
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Outside PMA Unique Leads',
            columnDef: 'uniqueLeadsNonPma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Outside PMA Lead Sales',
            columnDef: 'leadSalesNonPma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Outside PMA Close Rate',
            columnDef: 'closeRateNonPma',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            columnClasses: ['left-border'],
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Response Rate < 1hr',
            columnDef: 'responseRate60',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageOneDecimal,
            clickable: false
        },
    ];
}
